import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// Firebase
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as labs from 'vuetify/labs/VDataTable'
import * as baseComponents from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
// vue-datepicker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const vuetify = createVuetify({
  components: {
    ...labs,
    ...baseComponents,
    VueDatePicker
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        dark: true,
        colors: {
          background: '#616161', // background
          surface: '#424242', // foreground
          primary: '#0096FF',
          secondary: '#03DAC6'
        }
      },
      light: {
        dark: false,
        colors: {
          background: '#EEEEEE', // '#FFFFFF', // background
          surface: '#E0E0E0', // foreground
          primary: '#0096FF',
          secondary: '#03DAC6'
        }
      }
    }
  }
})

/* code from our Firebase console */
const firebaseConfig = {
  apiKey: 'AIzaSyDEyHD1dV-TUj3CoeyPIjyEIbbhmJ-nohk',
  authDomain: 'soccerlog-291d3.firebaseapp.com',
  projectId: 'soccerlog-291d3',
  storageBucket: 'soccerlog-291d3.appspot.com',
  messagingSenderId: '688005330172',
  appId: '1:688005330172:web:c3f1e408270a5131395379',
  measurementId: 'G-814E2DEQDS'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// initialize firebase auth
const auth = getAuth(app)
export { app, auth }

// export default new Vuetify({
//   theme: { dark: true },
// })

// pinia
const pinia = createPinia().use(piniaPluginPersistedstate)

// use(store).
createApp(App).use(router).use(vuetify).use(pinia).mount('#app')
