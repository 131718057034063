import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/indexPage.vue'
import GameLog from '../views/gameLog.vue'
import myTeam from '../views/myTeam.vue'
import Login from '../views/loginPage.vue'
import mySettings from '../views/mySettings.vue'
import signup from '../views/signUp.vue'
import TeamStats from '../views/teamStats.vue'
import MyOrg from '../views/myOrg.vue'
import CreateGame from '../views/createGame.vue'
import GameStats from '../views/gameStats.vue'
import About from '../views/aboutApp.vue'

import { useGameState } from '../stores/gameState'

function checkAuth (to, from, next) {
  const store = useGameState()
  // console.log(to + ' ' + from + ' ' + next)

  if (!store.loggedIn) {
    next('/login')
  }
  next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: signup
  },
  {
    path: '/gameLog',
    name: 'game log',
    component: GameLog,
    beforeEnter: checkAuth
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/gameLog.vue')
  },
  {
    path: '/myTeam',
    name: 'My Team',
    component: myTeam,
    beforeEnter: checkAuth
  },
  {
    path: '/mySettings',
    name: 'My Settings',
    component: mySettings,
    beforeEnter: checkAuth
  },
  {
    path: '/teamStats',
    name: 'Team Stats',
    component: TeamStats,
    beforeEnter: checkAuth
  },
  {
    path: '/myOrg',
    name: 'My Org Settings',
    component: MyOrg,
    beforeEnter: checkAuth
  },
  {
    path: '/createGame',
    name: 'Create Game',
    component: CreateGame,
    beforeEnter: checkAuth
  },
  {
    path: '/gameStats',
    name: 'Game Stats',
    component: GameStats,
    beforeEnter: checkAuth
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
