
export async function getPerms (store) {
  const permCheck = {
    UID: store.auth.user.uid,
    OrgID: store.OrgID,
    TeamID: store.TeamID
  }
  const data = JSON.stringify(permCheck)
  const res = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/permCheck',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'App-Token': process.env.VUE_APP_BACKEND_TOKEN },
      body: data
    }).then((res) => res.json())
  return res
}

export const PermissionLevels = [
  { Viewer: 1 },
  { Statistician: 3 },
  { Coach: 5 },
  { OrgManager: 8 },
  { GlobalAdmin: 55 }
]

// export function getPermLevel (store) {
//   const p = getPerms(store)
//   console.log(p)
// }

// const checkPermissions = async () => {
//   const perms = await getPerms(store)

//   // console.log(perms)

//   if (perms.RBAC === 'Viewer') {
//     admin.value = false
//   } else {
//     admin.value = true
//   }
// }
