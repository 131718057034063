
export async function logActivity (store, page, file, gameid) {
  const permCheck = {
    UID: store.auth.user.uid,
    OrgID: store.OrgID,
    TeamID: store.TeamID,
    Page: page,
    AppFile: file,
    GameID: gameid
  }
  const data = JSON.stringify(permCheck)

  await fetch(process.env.VUE_APP_BACKEND_URL + '/api/userTelemetry',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'App-Token': process.env.VUE_APP_BACKEND_TOKEN },
      body: data
    }).then((res) => res.json())
}
